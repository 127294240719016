import React from 'react'

const OrderCardLoading = () => {
  return (
    <div class="p-6 border w-full border-gray-200 rounded-lg shadow-lg bg-white flex flex-col justify-between animate-pulse">
  <div class="mb-4">
    <div class='w-full flex flex-row justify-between'>
      <div class="h-5 bg-gray-200 rounded w-1/4"></div>
      <div class="h-5 bg-gray-200 rounded w-4"></div>
    </div>
    <div class="h-4 bg-gray-200 rounded w-1/2 mt-1"></div>
    <div class="h-4 bg-gray-200 rounded w-1/4 mt-1"></div>
    <div class="h-4 bg-gray-200 rounded w-1/2 mt-2"></div>
  </div>
  <div class="flex justify-between items-center">
    <div class="h-5 bg-gray-200 rounded w-1/4"></div>
    <div class="flex space-x-2">
      <div class="h-5 bg-gray-200 rounded w-4"></div>
      <div class="h-5 bg-gray-200 rounded w-4"></div>
    </div>
  </div>
</div>
  )
}

export default OrderCardLoading