import React from 'react'

const CategoryCardLoading = () => {
  return (
<div class="w-full bg-white shadow-lg  h-44 rounded-lg overflow-hidden animate-pulse">
  <div class="bg-gray-200 h-[70%] w-full"></div>
  <div class="p-4 w-full">
    <div class="h-4 bg-gray-200 rounded w-1/3"></div>
  </div>
</div>
  )
}

export default CategoryCardLoading