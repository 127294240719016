import LayOut from "./components/layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Categories from "./pages/categories";
import SearchPage from "./pages/search";
import Checkout from "./pages/checkout";
import LayOutProfile from "./components/layoutProfile";
import Orders from "./pages/Orders";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className=" ">
      
      <Header/>
    <Routes>
      <Route path="/" element={<LayOut />}>
        <Route index element={<HomePage />} />
        <Route path="/category/:id" element={<Categories />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/checkout" element={<Checkout />} />
      </Route>
      <Route path="/account" element={<LayOutProfile />}>
        {/* <Route path="/profile" element={<Profile />} /> */}
        <Route path="/account/orders" element={<Orders />} />

      </Route>
    </Routes>
    <ToastContainer className="z-[9999999]" />
    </div>
  );
}

export default App;
