import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import SideBarCategories from "./sideBarCategories";
import CardList from "./CardList";
import { axiosServer, buildLink } from "../lib/server";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./footer";
const LayOut = () => {
  const categories = useSelector((state) => state.categories.categories);
  const dispatch = useDispatch(); // Get the dispatch function
  const location = useLocation();

  var prevScroll = 200;
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    console.log(cart.openCartSid);
  }, [cart.openCartSid]);
  useEffect(() => {
    if (window.innerWidth < 600) {
      let header = document.getElementById("headersticky");
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop < 200) {
          // header.classList.remove("sticky")
          header.classList.replace("top-0", "-top-40");
        } else {
          if (scrollTop > prevScroll) {
            //  console.log(`prev${prevScroll}`);

            // header.classList.add("sticky")
            header.classList.replace("top-0", "-top-40");
          } else if (scrollTop < prevScroll) {
            // header.classList.remove("sticky")
            header.classList.replace("-top-40", "top-0");
          }
          prevScroll = scrollTop;
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    axiosServer.get(buildLink("getCategories")).then((result) => {
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch({ type: "SET_CATEGORIES", payload: result.data });
    });
  }, []);
  return (
    <div className=" flex flex-col  h-full">

      <div className=" gap-5 flex h-full flex-row  px-4 max-md:p-0">
        {location.pathname !== "/checkout" && (
          <div className="  sticky top-20 w-full h-fit  max-md:hidden">
            <SideBarCategories />
          </div>
        )}

        <div className=" h-full md:max-w-[60%] max-w-full min-w-[60%] pt-12  max-md:pt-1 max-md:w-full mt-2 mx-auto">
          <Outlet />
        </div>
        {location.pathname !== "/checkout" && (
          <div
            className={`  bg-gray-100  sticky max-md:top-0 top-20 w-full h-fit transition-all duration-500   max-md:fixed max-md:z-50  ${
              cart.openCartSid
                ? "max-md:-right-0 max-md:opacity-100"
                : " max-md:opacity-5  max-md:-right-[200%]"
            } `}
          >
            <CardList />
          </div>
        )}
      </div>


      <div>
        <Footer />
      </div>
    </div>
  );
};

export default LayOut;
