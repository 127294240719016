import React from 'react'
import logo from "../assets/images/old_house.png";
import brand1 from '../assets/images/brand1.png';
import brand2 from '../assets/images/brand2.jpeg';
import { FaFacebook, FaFacebookF, FaFileInvoiceDollar, FaHeart, FaInstagram, FaLocationArrow, FaPhone, FaTwitter, FaUser, FaWhatsapp } from 'react-icons/fa';
import { BsTiktok } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="relative bg-[#d3d3d3] bg-opacity-20 pt-8 pb-6">
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap text-left lg:text-left">
        <div className="w-full lg:w-6/12 px-4">
          <h4 className="text-3xl fonat-semibold text-gray-400">Welcome To Old House!</h4>
          <h5 className="text-lg  mt-0 mb-2 text-blueGray-600">
            Our Store is in Beirut , Dekwaneh , besid some location . <MdLocationOn className=' text-[#8BC542]  inline-block'/>
          </h5>
          <div className="mt-6 flex flex-row lg:mb-0 mb-6">
            <a href='https://www.tiktok.com/@proofstore1966?_t=8rF6i3Zgwdw&_r=1' className="bg-white flex text-center text-black text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
              <BsTiktok className=' my-auto mx-auto'/></a>

              <a href='https://www.facebook.com/profile.php?id=100082474003171&mibextid=ZbWKwL'  className="bg-white flex text-center text-blue-600 text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
              <FaFacebookF className=' my-auto mx-auto'/></a>
              
              <a  className="bg-white flex text-center  text-orange-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
             <FaLocationArrow className=' my-auto mx-auto'/></a>
              
              <a href='https://www.instagram.com/youssef_tannouri/profilecard/?igsh=aHYyaDFmNGdybG5m' className="bg-white flex text-center text-pink-400 text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
              <FaInstagram  className=' my-auto mx-auto'/>
            </a>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <div className="flex flex-wrap items-top mb-6">
            <div className="w-full max-md:pb-4 lg:w-4/12 px-4 ml-auto">
              <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Contact Us</span>
              <ul className="list-unstyled">
                <li>
                <a
  className="text-green-700 flex flex-row hover:text-blueGray-800 font-semibold gap-2  pb-2 text-sm"
  href="https://wa.me/81848748"
  target="_blank"
  rel="noopener noreferrer"
>
  <FaWhatsapp className=' my-auto'/>
  Whatsapp
</a>
                </li>
                <li>
                <a
  className=" flex flex-row text-blueGray-600 font-semibold gap-2  pb-2 text-sm"
  href="tel:03848747"
>
  <FaPhone className=' my-auto'/>
  03848747
</a>
                </li>
              
              </ul>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Profile</span>
              <ul className="list-unstyled">
                <li>
                  <Link to={'profile'} className="text-[#669e22]    font-normal gap-2 pb-2 flex flex-row text-sm"><FaUser/>Profile</Link>
                </li>
                <li>
                <Link to={'orders'} className="text-[#669e22]   font-normal gap-2 pb-2 flex flex-row text-sm"><FaFileInvoiceDollar/>Orders</Link>
                </li>
                <li>
                <Link to={'wishlist'} className="text-[#669e22]   font-normal gap-2 pb-2 flex flex-row text-sm"><FaHeart/>Wishlist</Link>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-6 border-blueGray-300"/>
      <div className="flex flex-wrap items-center  md:justify-between justify-center">
        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
          <div className="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © <span id="get-current-year">2024</span><a href="https://www.creative-tim.com/product/notus-js" className="text-blueGray-500 hover:text-gray-800" target="_blank"/> Notus JS by
            <a href="https://www.creative-tim.com?ref=njs-profile" className="text-cyan-500 hover:text-blueGray-800"> SoftPro Team</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer 