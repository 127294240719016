import React, { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/images/old_house.png'
import Footer from "./footer";
import { FaFileInvoiceDollar, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
const LayOutProfile = () => {
  const stateAccount = useSelector((state) => state.accountReducer);
const navigate = useNavigate()
  useEffect(()=>{
    if(!stateAccount.loading && !stateAccount.loged ){
      navigate("/")
    }
  },[stateAccount.loged , stateAccount.loading])
  return (
    <div className="   flex flex-col h-full ">
      {/* <Header /> */}
   
        <div className=" w-full  h-full  pt-14  justify-start max-md:pt-1 max-md:w-full mt-2 mx-auto  px-2  flex flex-row gap-1">
                <div className=" max-md:hidden h-screen w-[20%]  sticky top-0   py-5  border-r border-gray-500 flex flex-col gap-4 border-opacity-20">
                    <Link className=" px-4 py-2 text-xl  flex flex-row text-[#676767] gap-5  hover:text-[#8BC542] transition-all" to={'/account/profile'}><FaUser className=" my-auto"/><span>Profile</span></Link>
                    <Link className=" px-4 py-2 text-xl  flex flex-row text-[#676767] gap-5  hover:text-[#8BC542] transition-all" to={'/account/orders'}><FaFileInvoiceDollar className=" my-auto"/><span>Orders</span></Link>
                </div>

        <div className=" h-full py-3 md:max-w-[80%]  w-full max-w-full min-w-[60%] ">
      {stateAccount.loading?
      <div className=" w-full h-[80vh] flex  justify-center ">
        <div className=" w-20  h-20 my-auto animate-bounce opacity-25 relative overflow-hidden "> <img  className=" w-full h-full" src={logo}/></div>
      </div>:
          <Outlet />
  }
        </div>
        </div>
   


      
        <Footer />
      
    </div>
  );
};

export default LayOutProfile;
