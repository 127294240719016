import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import {
  MdClose,
  MdOutlineSearch,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCartItems } from "../reducer/storeReducer";
import logo from "../assets/images/old_house.png";
import Account from "./account";
import Cookies from "js-cookie";
import { axiosServer, buildLink } from "../lib/server";
const Header = () => {
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const searchRef = useRef(null);
  const [linkBeforeSearch, setLinkBeforeSearch] = useState("/");
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCartItems());
  }, [dispatch]);


  const  changeCurrency =async(e)=>{
    Cookies.set("currency",e.target.value)
      const response = await axiosServer.get(buildLink("updateCurrency")+"?currency="+e.target.value);
    window.location.reload()
      console.log(response)
  }
  // useEffect(()=>{

  // if(searchRef.current  ){
  //   console.log(searchRef.current.value)
  //   setIsSearching(true)
  // }else{
  //   setIsSearching(false)
  // }
  // },[searchRef.current.value])

  // useEffect(()=>{
  //   console.log(window.location.pathname)
  // },[window.location.pathname])

  return (
    <div
      id="headersticky"
      className=" md:py-5 overflow-hidden  max-md:sticky fixed   top-0  transition-all duration-500  z-50 py-1  h-16 max-md:h-[8rem] pt-2 gap-3 bg-white shadow-sm  w-full flex flex-col justify-center "
    >
      <div className=" flex flex-row justify-between max-md:px-1 px-5 ">
        <div className=" flex flex-row justify-start gap-2">
        <Link to={"/"} className=" text-3xl font-extrabold">
          <div className="w-24">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <select
        className=" w-full  outline-none border-none"
        onChange={(e)=>changeCurrency(e)}
        value={Cookies.get("currency")?Cookies.get("currency"):'USD'}
        >
          
          <option value="USD">USD</option>
          <option value="LBP">LBP</option>

        </select>
        </div>
        <div className=" h-10 my-auto relative overflow-hidden max-md:hidden w-[40%] ">
          <div className=" flex flex-row border  border-gray-400  border-opacity-20 justify-start w-full relative h-full bg-[#dfdfdf]  rounded-md  bg-opacity-30 ">
            <button
              onClick={() => {
                navigate("/");
                searchRef.current.value = "";
              }}
              className={` ${
                window.location.pathname == "/search"
                  ? " translate-x-0"
                  : " -translate-x-10"
              } rounded-full  transition-all hover:bg-gray-200 p-2 hover:bg-opacity-25 right-2 text-gray-400 top-0`}
            >
              <FaArrowLeft className=" my-auto" />
            </button>
            {window.innerWidth > 650 && (
              <input
                ref={searchRef}
                onChange={(e) => {
                  if (e.target.value != "") {
                    setIsSearching(true);
                  } else {
                    setIsSearching(false);
                  }
                  navigate("/search?query=" + e.target.value);
                }}
                placeholder=" Search"
                className=" placeholder:italic px-2 placeholder:text-gray-400 py-3 outline-none text-black text-sm placeholder:text-sm bg-transparent w-full h-full "
              ></input>
            )}
            <button
              onClick={() => {
                setIsSearching(false);
                navigate("/search?query=");
                searchRef.current.value = "";
              }}
              className=" absolute rounded-full hover:bg-gray-200 p-1  hover:bg-opacity-25 right-3 text-black top-1"
            >
              {isSearching ? (
                <MdClose className=" text-2xl my-auto " />
              ) : (
                <MdOutlineSearch className=" text-2xl my-auto" />
              )}
            </button>
          </div>
        </div>
        <div className=" my-auto h-10 flex flex-row justify-end gap-5">
          <Account />
          <button
            onClick={() =>
              dispatch({ type: "SET_OPEN_CART_SIDE", payload: true })
            }
            className=" bg-white border-b-2 border-[#8CC640]  px-2 flex flex-row gap-3 justify-center rounded-md relative  "
          >
            <MdOutlineShoppingCart className=" text-[#8CC640]  text-xl my-auto" />
            <span className=" rounded-full   flex justify-center text-center  bg-[#8CC640]  my-auto w-7 h-7">
              <div className=" text-white my-auto">
                {cart.loading ? (
                  <div className="w-4 h-4 rounded-full bg-white animate-ping "></div>
                ) : (
                  cart.cartCount
                )}
              </div>
            </span>

            {/* <div className=' absolute  bg-red-500 w-5  animate-ping h-5 rounded-full -right-2 -top-2'></div> */}
          </button>
        </div>
      </div>

      <div className="   md:hidden  w-full h-10 ">
        <div className="flex flex-row  justify-start w-full relative h-full bg-[#ababab]  rounded-md  bg-opacity-30 ">
          <button
            onClick={() => {
              navigate("/");
              searchRef.current.value = "";
            }}
            className={` ${
              window.location.pathname == "/search"
                ? " translate-x-0"
                : " -translate-x-10"
            } rounded-full  transition-all hover:bg-gray-200 p-2 hover:bg-opacity-25 right-2 text-gray-400 top-0`}
          >
            <FaArrowLeft className=" my-auto" />
          </button>
          {window.innerWidth < 650 && (
            <input
              ref={searchRef}
              onChange={(e) => {
                if (e.target.value != "") {
                  setIsSearching(true);
                } else {
                  setIsSearching(false);
                }
                navigate("/search?query=" + e.target.value);
              }}
              placeholder=" Search"
              className=" placeholder:italic px-2 placeholder:text-gray-400 py-3 outline-none text-black text-sm placeholder:text-sm bg-transparent w-full h-full "
            ></input>
          )}
          <button
            onClick={() => {
              setIsSearching(false);
              searchRef.current.value = "";
            }}
            className=" absolute rounded-full hover:bg-gray-200 p-1  hover:bg-opacity-25 right-3 text-black top-1"
          >
            {isSearching ? (
              <MdClose className=" text-2xl my-auto " />
            ) : (
              <MdOutlineSearch className=" text-2xl my-auto" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
