import React, { useEffect, useState } from 'react'
import { axiosServer, buildLink } from '../lib/server'
import { FiMoreHorizontal } from 'react-icons/fi';
import { MoreVert } from '@mui/icons-material';
import OrderCard from '../components/orderCard';
import CategoryCardLoading from '../components/categoryCardLoading';
import OrderCardLoading from '../components/orderCardLoading';
import { ModalOrderDetails } from '../components/orderDetails';




const Orders = () => {
  const [loadingDetails,setLoadingDetails] = useState(false)
const [isOpen,setisOpen] = useState(false)
const [orders,setOrders] = useState(null)
const [loadingOrders,setLoadingOrders] = useState(false)
const [orderProducts,setOrderProducts] = useState([])
const [orderOpened,setOrderDetails] = useState(null)
    const getOrders =()=>{
      setLoadingOrders(true)
        axiosServer.get(buildLink('getOrders')).then((res)=>{
          setLoadingOrders(false)
           setOrders(res.data.data)
        })
    }
    useEffect(()=>{
      console.log(orderOpened)
    },[orderOpened])
    const getOrderDetails =()=>{
      setLoadingDetails(true)
        axiosServer.get(buildLink('getOrders')+"?order_id="+isOpen).then((res)=>{
          setLoadingDetails(false)
           setOrderProducts(res.data.data)
        })
    }
    useEffect(()=>{
getOrders()
    },[])

    useEffect(()=>{
      if(isOpen){
      getOrderDetails()
      }
    },[isOpen])
  return (
    <div className='  h-full flex  px-4 flex-col gap-2  '>
        <div className=' flex flex-col   justify-start text-left'>
        <h2 className=' text-2xl '>Your Orders</h2>
        <p className=' text-sm text-gray-400'>view and check check your orders status</p>
        </div>

        {loadingOrders&& <div className="grid mt-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <OrderCardLoading/>
          <OrderCardLoading/>   <OrderCardLoading/>
          <OrderCardLoading/>
          <OrderCardLoading/>
          <OrderCardLoading/>   <OrderCardLoading/>

          
        </div>}
        <div className="grid mt-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {orders&& orders.map((order) => (
         <OrderCard setOrderDetails={setOrderDetails} order={order} onView={setisOpen} isOpen={isOpen}/>
        ))}
      </div>

        <ModalOrderDetails orderOpened={orderOpened} isOpen={isOpen?true:false} orderProducts={orderProducts} loading={loadingDetails} setIsOpen={setisOpen}   />
      
    </div>
  )
}

export default Orders