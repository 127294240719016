import React, { useEffect, useRef, useState } from "react";
import { axiosServer, buildLink } from "../lib/server";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

const SideBarCategories = () => {
  const categories = useSelector((state) => state.categories); 
  const { id } = useParams();
  const categoryRefs = useRef({}); // To store refs for each category


  useEffect(() => {
    if (id && categoryRefs.current[id]) {
      categoryRefs.current[id].scrollIntoView({
        behavior: 'smooth', // Optional: for smooth scrolling
        block: 'start',     // Scroll to the start of the element
      });
    }
  }, [id]);
  return (
    <div className=" px-5  h-full  overflow-y-auto py-4   border-r border-gray-300 border-opacity-30 max-md:hidden">
      <div className=" flex flex-col h-full gap-4">
        <h2 className=" font-bold text-xl">Categories</h2>
        <br></br>
        <div className=" text-lg  flex flex-col gap-2 ">
          {categories.categories && !categories.loading  ?
            categories.categories.map((category) => (
              <Link  ref={(el) => (categoryRefs.current[category.id] = el)} to={"category/"+category.id} className={` ${id && id == category.id ?" border-b-4 border-[#8BC542] border-opacity-80 bg-gray-300 bg-opacity-30":" bg-white border-opacity-30"} hover:bg-[#8BC542] transition-all hover:text-white rounded-md border-b border-gray-400  py-2 px-3 text-sm font-bold`}>
                {category.description}
              </Link >

            )
            ):

            <div class="animate-pulse">
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
    <div className="p-4 h-10 bg-gray-200 rounded mt-2"></div>
</div>
            
            }
        </div>
      </div>
    </div>
  );
};

export default SideBarCategories;
