import React, { useEffect, useRef, useState } from "react";
import { axiosServer, buildLink } from "../lib/server";
import { FaPlus } from "react-icons/fa";
import productImage from "../assets/images/368~1.jpg";
import { Link, useParams } from "react-router-dom";
import Productcardloading from "../components/productcardloading";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Product from "../components/product";
const Categories = () => {
  const [items, setItems] = useState(null);
  const { id } = useParams();
  const categories = useSelector((state) => state.categories);

  const [loading, setLoading] = useState(true);
  const categoryRefs = useRef({}); // To store refs for each category

  useEffect(() => {
    if (id && categoryRefs.current[id]) {
      categoryRefs.current[id].scrollIntoView({
        behavior: "smooth", // Optional: for smooth scrolling
        block: "start", // Scroll to the start of the element
      });
    }
  }, [id]);
  useEffect(() => {
    // Create a cancel token to cancel the previous request
    const source = axios.CancelToken.source();
    setLoading(true);

    axiosServer
      .get(buildLink("getItemsByCategory") + `?category_id=${id}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          // Handle other errors here
          setLoading(false);
        }
      });

    // Cleanup function to cancel the request if component unmounts or id changes
    return () => {
      source.cancel(
        "Operation canceled due to new request or component unmount."
      );
    };
  }, [id]);

  return (
    <div className="flex flex-col gap-2">
      <div className=" text-lg md:hidden py-2 shadow-md px-3 rounded-md overflow-x-auto flex w-full flex-row gap-2 ">
        {categories.categories && !categories.loading ? (
          categories.categories.map((category) => (
            <Link
              ref={(el) => (categoryRefs.current[category.id] = el)}
              to={"/category/" + category.id}
              className={` ${
                id && id == category.id
                  ? "bg-[#8BC542] text-white "
                  : "text-gray-800 "
              } hover:bg-[#8BC542] min-w-fit transition-all hover:text-white rounded-md border-b border-gray-400 border-opacity-30 py-2 px-3 text-sm font-bold`}
            >
              {category.description}
            </Link>
          ))
        ) : (
          <div class="animate-pulse flex flex-row gap-2">
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
            <div className="p-4 w-36 h-10 bg-gray-200 rounded mt-2"></div>
          </div>
        )}
      </div>

      {loading ? (
        <div className=" grid py-4  gap-1  px-2 grid-cols-5 max-md:grid-cols-2">
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
        </div>
      ) : (
        <div className=" grid  gap-1 py-4  px-2 grid-cols-5 max-md:grid-cols-2">
          {items && items.map((item) => <Product product={item} />)}
        </div>
      )}
    </div>
  );
};

export default Categories;
