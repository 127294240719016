import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { FaFileInvoice, FaSpinner } from "react-icons/fa";
import { ArrowRightAlt } from "@mui/icons-material";
import { BsBag, BsTruck } from "react-icons/bs";
import productImage from "../assets/images/368~1.jpg";
export const ModalOrderDetails = ({
  orderOpened,
  loading,
  isOpen,
  setIsOpen,
  orderProducts = [],
}) => {
  const statusStyles = {
    delivery: "text-yellow-600 bg-yellow-100",
    pickup: "text-blue-600 bg-blue-100",
  };
  const payment_methode_icons = {
    delivery: <BsTruck className="my-auto text-lg" />,
    pickup: <BsBag className=" my-auto text-sm" />,
  };
  return (
    <AnimatePresence className="w-full ">
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={setIsOpen}
          as="div"
          className="fixed w-full inset-0 z-50 flex items-center justify-center overflow-y-auto"
        >
          <div className="flex  w-full flex-col py-8  text-center">
            <Dialog.Overlay />
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.85,
                },
              }}
              exit={{
                opacity: 0,

                transition: {
                  ease: "easeOut",
                  duration: 0.85,
                },
              }}
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </motion.div>

            <motion.div
              className="flex items-end w-full  justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
              initial={{
                opacity: 0,
                scale: 0.75,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.85,
                  type: "spring",
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ease: "easeOut",
                  duration: 0.85,
                  type: "spring",
                },
              }}
            >
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div
                className="inline-block max-md:w-[99%] w-[80%] align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="px-4    pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg gap-2 flex flex-col text-start leading-6 font-medium text-gray-900"
                        id="modal-headline"
                      >
                        <div className=" flex max-md:flex-col gap-2 flex-row justify-between ">
                          <div className=" flex flex-col gap-2 text-left">
                            <h2 className=" font-bold text-3xl">
                              Order : #{orderOpened.order_id}
                            </h2>
                            <p className="text-sm text-gray-400">
                              View your order (
                              <span className=" font-bold text-gray-700 ">
                                #{orderOpened.order_id}
                              </span>
                              ) and order products{" "}
                            </p>
                            <p className="mt-2">
                              <span
                                className={` px-3 py-1 rounded-full flex flex-row gap-3 w-fit text-xs font-medium ${
                                  statusStyles[orderOpened.payment_method]
                                }`}
                              >
                                <span className=" my-auto">
                                  {
                                    payment_methode_icons[
                                      orderOpened.payment_method
                                    ]
                                  }
                                </span>
                                <span className=" my-auto">
                                  {" "}
                                  {orderOpened.payment_method}{" "}
                                </span>
                                {orderOpened.payment_method == "delivery" && (
                                  <div className=" flex flex-col gap-3">
                                    <div className="   flex flex-row justify-start gap-1">
                                      <ArrowRightAlt className="  my-auto" />
                                      <span className=" my-auto">
                                        {orderOpened.Description}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </span>

                              {orderOpened.payment_method == "delivery" && (
                                <div className=" pt-3 pl-5 flex flex-row gap-2 text-xs text-gray-400">
                                  <span>Address Info</span>
                                  <span className=" my-auto">
                                    {orderOpened.address_info}
                                  </span>
                                </div>
                              )}
                            </p>
                          </div>
                          <div className=" flex flex-col gap-2 text-left">
                            <p className=" text-sm text-gray-400">
                              Date : {orderOpened.date_added}
                            </p>
                            <h2 className=" text-xl ">
                              Total :{" "}
                              <span className=" text-red-700">
                                {orderOpened.total}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Dialog.Title>
                      <div className="mt-2">
                        <Dialog.Panel as="div" className=" pt-5">
                          <div className="overflow-x-auto h-72 overflow-y-auto relative">
                            {!loading && orderProducts ? (
                              <table className="table-auto  rounded-md w-full text-left ">
                                <thead>
                                  <tr className="bg-[#8BC542]   text-white">
                                    <th className="px-4 py-2 text-center">#</th>
                                    <th className="px-4 py-2 text-center">
                                      {" "}
                                      Name
                                    </th>
                                    <th className="px-4 py-2 text-center">
                                      Price
                                    </th>
                                    <th className="px-4 py-2 text-center">
                                      Quantity
                                    </th>
                                    <th className="px-4 py-2 text-center">
                                      Total
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {orderProducts.map((product, index) => (
                                    // <></>
                                    <tr
                                      key={product.order_product_id}
                                      className={`${
                                        index % 2 === 0
                                          ? "bg-white"
                                          : "bg-gray-50"
                                      } hover:bg-gray-100`}
                                    >
                                      <td className=" px-4 py-2 text-center">
                                        <img
                                          className=" min-w-12 max-w-12"
                                          src={productImage}
                                        />
                                      </td>
                                      <td className=" w-20 px-4 py-2 text-center">
                                        <h2 className=" w-20 md:w-60">
                                          {product.description}
                                        </h2>
                                      </td>
                                      <td className=" px-4 py-2 text-center">
                                        {product.cost}
                                      </td>
                                      <td className=" px-4 py-2 text-center">
                                        {product.quantity}
                                      </td>
                                      <td className=" text-red-600 px-4 py-2 text-center ">
                                        {product.total_cost}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <div className=" w-full flex justify-center text-center ">
                                <FaSpinner className=" w-11 animate-spin " />
                              </div>
                            )}
                          </div>
                        </Dialog.Panel>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    tabIndex={0}
                    className="w-full inline-flex bg-[#8BC542] justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white  sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
