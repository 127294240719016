import React, { useCallback, useEffect, useRef, useState } from "react";
import { axiosServer, buildLink } from "../lib/server";
import { FaPlus, FaSearch } from "react-icons/fa";
import productImage from "../assets/images/368~1.jpg";
import { Link, useLocation, useParams } from "react-router-dom";
import Productcardloading from "../components/productcardloading";
import { useSelector, useDispatch } from "react-redux";
import { FiSlash } from "react-icons/fi";
import _ from "lodash";
import axios from "axios";
import Product from "../components/product";
const SearchPage = () => {
  const [items, setItems] = useState(null);

  const [query, setQuery] = useState("");
  const location = useLocation();
  const [noData, setNodata] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get("query");
    setQuery(queryParam || ""); // Set the query or an empty string if null
  }, [location.search]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (query) {
      seachItem(query, source);
    } else {
      setLoading(false);
      setNodata(true);
    }
    return () => {
      source.cancel(
        "Operation canceled due to new query or component unmount."
      );
    };
  }, [query]);

  const seachItem = async (searchVal, source) => {
    //   if (searchVal.length >= 3) {

    try {
      setNodata(false);
      setLoading(true);
      const res = await axiosServer.get(
        buildLink("searchItems") + `?search_query=${searchVal}`,
        { cancelToken: source.token }
      );
      setLoading(false);
      setItems(res.data);
      if (res.data.length == 0) {
        setNodata(true);
      } else {
        setNodata(false);
      }
    } catch (e) {
      //   console.error(e);
      // setLoading(false);
      // }
    }
  };

  //   const handleInputChange = (e) => {
  //     const searchVal = e.target.value;
  //     seachItem(searchVal);
  //   };

  //   function searchDescktop(e){
  //     const searchVal=e.target.value;
  //     if(searchVal.length >= 3){
  //       seachItem(searchVal);

  //     }else{
  //       setOpenSearchDesck(false)
  //       setSearchList([]);
  //     }
  //   }

  return (
    <div className="flex h-full flex-col gap-2">
      {loading ? (
        <div className=" grid  gap-1 px-2 grid-cols-5 max-md:grid-cols-2">
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
          <Productcardloading />
        </div>
      ) : noData ? (
        !query ? (
          <div className=" w-full mx-auto h-[80vh] gap-3 flex flex-col justify-center text-center ">
            <FaSearch className=" mx-auto text-5xl text-[#8BC542]" />
            <h2 className=" text-3xl font-bold ">Search For Something...</h2>
          </div>
        ) : (
          <div className=" w-full mx-auto h-[80vh] gap-3 flex flex-col justify-center text-center ">
            <FiSlash className=" mx-auto text-5xl text-[#8BC542]" />
            <h2 className=" text-3xl font-bold ">
              No Results For {"(" + query + ")"}
            </h2>
          </div>
        )
      ) : (
        <div className=" grid  gap-1  px-2 grid-cols-5 max-md:grid-cols-2">
          {items && items.map((item) => <Product product={item} />)}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
