import { ArrowRightAlt, MoreVert, RefreshTwoTone, ShoppingBasket } from '@mui/icons-material'
import React from 'react'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FiLogOut } from 'react-icons/fi';
import { FaEye, FaFileInvoiceDollar, FaTrash, FaTruckPickup } from 'react-icons/fa';
import { BsBag, BsTruck } from 'react-icons/bs';
import {ModalOrderDetails} from '../components/orderDetails'

const OrderCard = ({order,onView,setOrderDetails}) => {
 
  const statusStyles = {
    delivery: 'text-yellow-600 bg-yellow-100',
    pickup: 'text-blue-600 bg-blue-100',
  };
  const payment_methode_icons = {
    delivery: <BsTruck className='my-auto text-lg' />,
    pickup: <BsBag className=' my-auto text-sm' />,

  }

  return (
    <div
            key={order.id}
            className="p-6 border w-full border-gray-200 rounded-lg shadow-lg bg-white flex flex-col justify-between">
            <div className="mb-4">
                <div className=' w-full flex flex-row justify-between '>
              <h3 className="text-lg font-semibold text-gray-900">
                Order ID: <span className="text-[#8BC542]">#{order.order_id}</span>
              </h3>
              <div>
              <button onClick={() =>{ setOrderDetails(order); onView(order.order_id)}} className=""><FaEye/></button>


      </div>
              </div>
              <p className="text-sm text-gray-300">Date: {order.date_added}</p>
              <p className="text-sm text-gray-600"> {order.product_count} Products </p>
              <p className="mt-2">
                <span
                  className={` px-3 py-1 rounded-full flex flex-row gap-3 w-fit text-xs font-medium ${statusStyles[order.payment_method]}`}
                >
                 <span className=' my-auto'>{payment_methode_icons[order.payment_method]}</span><span className=' my-auto'> {order.payment_method} </span>
                  {order.payment_method == 'delivery'&& <div className='   flex flex-row justify-start gap-1'><ArrowRightAlt className='  my-auto'/><span className=' my-auto'>{order.Description}</span></div>}
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-lg font-bold text-gray-800">{order.total}</span>
              <div className="flex space-x-2">
                <button
                  className=""
                >
                  <RefreshTwoTone/>
                </button>
                <button
                  className="px-3 py-1 text-sm font-medium text-white bg-red-500 rounded hover:bg-red-600"
                >
                 <FaTrash/>
                </button>
             
              </div>
            </div>
          
          </div>
  )
}

export default OrderCard